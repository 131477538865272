import React from "react";
import { Box } from "@mui/material";
import { padding } from "@mui/system";

interface BoxProps {
  children?: React.ReactNode;
  padding?: number;
  height?:string;
}

const Center = (props: BoxProps) => {
  return (
    <Box
      sx={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        height:props.height
      }}
      p={props.padding}

    >
      {props.children}
    </Box>
  );
};
export default Center;
