import { Box, Grid, Typography } from "@mui/material";
import Center from "../../core/Center";
import Img from "../../core/Img";
import showRoomPhoto from "../../assets/images/showRoomPhoto.jpeg";
import SocialMedai from "../contactUsComponents/socialMedai";

const OurShowroom = () => {
  return (
    <Box
      sx={{
        background: "#f1f1f1",
      }}
      py={5}
    >
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item md={8} sm={12} xs={12}>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid item md={5}>
              <Center padding={5}>
                <Img
                  imgUrl={showRoomPhoto}
                  width={"100%"}
                  height={"100%"}
                  borderRadius={"20px"}
                ></Img>
              </Center>
            </Grid>
            <Grid item md={5} sx={{ height: "100%",p:5 }}>
              <Center>
                <Box>
                  <Typography
                    variant="h3"
                    gutterBottom
                    sx={{
                      fontWeight: "bold",
                      color: "#060606",
                      fontSize: "35px",
                    }}
                  >
                    Our SHOWROOM{" "}
                  </Typography>
                  <Typography sx={{fontWeight:400}}>
                    We At Progressive Autosport, Provide Our Clients With High
                    End Cars, And Have Created A Big Name And Place In That
                    Industry. We Know The Meaning And The Value Of Luxury And
                    With Over A 100 Luxury Defining Cars In Stock, We Deliver It
                    To Them.
                  </Typography>
                  <SocialMedai mode={true}/>
                </Box>
              </Center>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
export default OurShowroom;
