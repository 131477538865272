import { Grid, Box, Divider } from "@mui/material";
import * as React from "react";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Slider from "react-slick";
import { NextBtn, PreviousBtn } from "../Slick";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import productList from "../../assets/carDetails/productList.json";
import { useNavigate } from "react-router-dom";
import Buttons from "./Buttons";
import Heading from "./Heading";
import Center from "../../core/Center";

import Home_banner1_sm from "./images/Home_Banner1_sm.png";
import Home_banner2 from "./images/home_banner2.png";
import Home_banner1 from "./images/Home_Banner1.png";
import Home_banner2_sm from "./images/home_banner2_sm.png";





function Banner() {
  let navigate = useNavigate();
  const [searchInput, setSearchInput] = React.useState<Number>();
  const onSearchHandler = () => {
    console.log(searchInput);
    if (searchInput === undefined) {
    } else {
      navigate(`buycar/${searchInput}`);
    }
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  return (
    <>
      <Box
        sx={{
          height: "100%",
          display: { lg: "block", md: "block", sm: "none", xs: "none" },
        }}
      >
        <Slider {...settings}> 
          <Grid
            container
            sx={{
              backgroundImage: `url(${Home_banner2})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              height: "100vh",
              justifyContent: "space-between",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Grid
              item
              md={8}
              sm={12}
              sx={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                marginTop: "100px",
              }}
            >
              <Center>
                <Box>
                  <Heading />
                  <Box
                    sx={{
                      width: "400px",
                      height: "auto",
                    }}
                  >
                    <Paper
                      component="form"
                      sx={{
                        p: "4px 4px",
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        height: 60,
                        background: "none",
                        boxShadow: "none",
                        borderBottom: "1px solid #fff",
                        borderRadius: "0",
                      }}
                    >
                      <Autocomplete
                        fullWidth
                        disableClearable
                        sx={{ border: "none" }}
                        onChange={(event, value) => setSearchInput(value?.id)}
                        options={productList}
                        getOptionLabel={(option) =>
                          option["VERIENT "] + " " + option["BRAND "]
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            label=""
                            sx={{ input: { color: "#fff", border: "none" } }}
                            // InputProps={{
                            //   disableUnderline: true,
                            // }}
                          />
                        )}
                      />
                      <IconButton
                        type="button"
                        sx={{
                          p: "10px",
                          color: "#fff",
                          
                        }}
                        aria-label="search"
                        onClick={onSearchHandler}
                      >
                        <SearchIcon />
                      </IconButton>
                    </Paper>
                    <Center>
                      <Buttons />
                    </Center>
                  </Box>
                </Box>
              </Center>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              backgroundImage: `url(${Home_banner1})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              height: "100vh",
              justifyContent: "space-between",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Grid
              item
              md={8}
              sm={12}
              sx={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                marginTop: "100px",
              }}
            >
              <Box px={0}>
                <Box>
                  <Heading />

                  <Box
                    sx={{ padding: "10px 0", width: "400px", height: "100%" }}
                  >
                    <Paper
                      component="form"
                      sx={{
                        p: "4px 4px",
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        height: 60,
                        background: "none",
                        boxShadow: "none",
                        borderBottom: "1px solid #fff",
                        borderRadius: "0",
                      }}
                    >
                      <Autocomplete
                        fullWidth
                        disableClearable
                        sx={{ border: "none" }}
                        onChange={(event, value) => setSearchInput(value?.id)}
                        options={productList}
                        getOptionLabel={(option) =>
                          option["VERIENT "] + " " + option["BRAND "]
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            label=""
                            sx={{ input: { color: "#fff" } }}
                            // InputProps={{
                            //   disableUnderline: true,
                            // }}
                          />
                        )}
                      />
                      <IconButton
                        type="button"
                        sx={{
                          p: "10px",
                          color: "#fff",
                          
                        }}
                        aria-label="search"
                        onClick={onSearchHandler}
                      >
                        <SearchIcon />
                      </IconButton>
                    </Paper>
                    <Center>
                      <Buttons />
                    </Center>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Slider>
      </Box>

      {/* small devide  */}

      <Box sx={{ display: { md: "none", sm: "block", xs: "block" } }}>
        <Slider {...settings}>
          <Grid
            container
            sx={{
              backgroundImage: "url(${Home_banner2_sm})",
              backgroundRepeat: "no-repeat",
              justifyContent: "center",
              height: { sm: "100%", xs: "100%" },
              alignItems: "center",
              display: "flex",
              backgroundSize: "100%",
              backgroundPosition: " center center",
              backgroundColor: "#000",
            }}
          >
            <Grid
              item
              md={6}
              sm={12}
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                marginTop: "100px",
              }}
            >
              <Box>
                <Box>
                  <Heading />
                  <Box sx={{ width: "100%",p:3 }}>
                    <Paper
                      component="form"
                      sx={{
                        p: "5px 5px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        background: "none",
                        boxShadow: "none",
                        borderBottom: "1px solid #fff",
                        borderRadius: "0"
                      }}
                    >
                      <Autocomplete
                        fullWidth
                        disableClearable
                        sx={{ border: "none" }}
                        onChange={(event, value) => setSearchInput(value?.id)}
                        options={productList}
                        getOptionLabel={(option) =>
                          option["VERIENT "] + " " + option["BRAND "]
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            label=""
                            sx={{ input: { color: "#fff" } }}
                            // InputProps={{
                            //   disableUnderline: true,
                            // }}
                          />
                        )}
                      />
                      <IconButton
                        type="button"
                        sx={{
                          p: "10px",
                          color: "#fff",
                          
                        }}
                        aria-label="search"
                        onClick={onSearchHandler}
                      >
                        <SearchIcon />
                      </IconButton>
                    </Paper>

                    <Buttons />
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              backgroundImage: `url(${Home_banner1_sm})`,
              backgroundRepeat: "no-repeat",
              justifyContent: "center",
              height: { sm: "100%", xs: "100%" },
              alignItems: "center",
              display: "flex",
              backgroundSize: "100%",
              backgroundPosition: " center center",
              backgroundColor: "#000",
            }}
          >
            <Grid
              item
              md={6}
              sm={12}
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                marginTop: "100px",
              }}
            >
              <Box>
                <Box>
                  <Heading />
                  <Box sx={{ width: "100%",p:3 }}>
                    <Paper
                      component="form"
                      sx={{
                        p: "2px 2px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        background: "none",
                        boxShadow: "none",
                        borderBottom: "1px solid #fff",
                        borderRadius: "0",
                      }}
                    >
                      <Autocomplete
                        fullWidth
                        disableClearable
                        sx={{ border: "none" }}
                        onChange={(event, value) => setSearchInput(value?.id)}
                        options={productList}
                        getOptionLabel={(option) =>
                          option["VERIENT "] + " " + option["BRAND "]
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            label=""
                            sx={{ input: { color: "#fff" } }}
                            // InputProps={{
                            //   disableUnderline: true,
                            // }}
                          />
                        )}
                      />
                      <IconButton
                        type="button"
                        sx={{
                          p: "10px",
                          color: "#fff",
                          
                        }}
                        aria-label="search"
                        onClick={onSearchHandler}
                      >
                        <SearchIcon />
                      </IconButton>
                    </Paper>

                    <Buttons />
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Slider>
      </Box>
    </>
  );
}

export default Banner;
