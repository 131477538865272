import {
  Box,
  Button,
  Grid,
  TextareaAutosize,
  Typography,
  TextField,
} from "@mui/material";
import React from "react";
import InputField from "../InputField";
import validationSchema from "./schema";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import axios from "axios";
const Form = () => {
  const [loader, setLoader] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: { firstName: "", lastName: "", email: "", message: "" },
  });

  const onSubmit = async (data: {
    firstName: string;
    lastName: string;
    email: string;
    message: string;
  }): Promise<any> => {
    console.log(data);
    var date = { currentTime: new Date().toLocaleString() };
    console.log(date.currentTime.slice(0, 10));
    axios
      .post("https://sheetdb.io/api/v1/y2e29ulq160ch", {
        id: "",
        name: `${data.firstName} ${data.lastName}`,
        email: `${data.email}`,
        message: `${data.message}`,
        date: `${date.currentTime.slice(0, 10)}`,
      })
      .then((response: any) => {
        setTosted({
          message: "sucessfully submited",
          status: true,
        });
      })
      .catch((error: any) => {
        setTosted({
          message: "No internet",
          status: true,
        });
      });
  };

  const [tosted, setTosted] = React.useState({
    message: "",
    status: false,
  });

  const handleClose = () => {
    if (tosted.status) {
      setTimeout(() => {
        setTosted({
          message: "",
          status: false,
        });
      }, 5000);
    }
  };

  React.useEffect(() => {
    handleClose();
  }, [tosted]);

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)} className="column">
        <Grid
          container
          spacing={2}
          p={4}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "#f1f1f1",
            borderRadius: "20px",
          }}
        >
          <Grid item sm={12} md={12} xs={12}>
            <Typography
              variant="h4"
              textAlign={"center"}
              sx={{ color: "#000" }}
            >
              SEND US AN EMAIL
            </Typography>
          </Grid>

          <Grid item md={4} xs={12}>
            <TextField
              fullWidth
              disabled={loader}
              variant="outlined"
              margin="normal"
              label="First Name"
              color={errors.firstName?.message ? "warning" : "primary"}
              helperText={errors.firstName?.message}
              {...register("firstName")}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              fullWidth
              disabled={loader}
              variant="outlined"
              margin="normal"
              label="Last Name"
              color={errors.lastName?.message ? "warning" : "primary"}
              helperText={errors.lastName?.message}
              {...register("lastName")}
            />
          </Grid>
          <Grid item md={8} sm={12} xs={12}>
            <TextField
              fullWidth
              disabled={loader}
              variant="outlined"
              margin="normal"
              label="Email id"
              color={errors.email?.message ? "warning" : "primary"}
              helperText={errors.email?.message}
              {...register("email")}
            />
          </Grid>

          <Grid item md={8} sm={12} xs={12}>
            <Typography>Message</Typography>
            <TextareaAutosize
              maxRows={10}
              aria-label="maximum height"
              color={errors.message?.message ? "warning" : "primary"}
              {...register("message")}
              placeholder=""
              defaultValue=""
              maxLength={300}
              style={{
                width: "100%",
                height: "100px",
                borderRadius: "5px",
                background: "#f1f1f1",
              }}
            />
            <Typography sx={{ fontWeight: 400, fontSize: 12, pl: 2 }}>
              {errors.message?.message ? errors.message?.message : ""}
            </Typography>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Button
              variant="outlined"
              size="large"
              sx={{ background: "#B2B2B2" }}
              type="submit"
              fullWidth
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>

      <Snackbar
        open={tosted.status}
        onClose={handleClose}
        message={tosted.message}
        key={"top" + "center"}
      />
    </Box>
  );
};

export default Form;
