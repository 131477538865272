import { Typography } from "@mui/material";
import React from "react";
import { makeStyles } from "tss-react/mui";

interface subTitleProps {
  title: string | undefined;
}
const useStyles = makeStyles()((theme) => ({
  root: {
    color: "transparent",
    WebkitTextStrokeWidth: "1px",
    WebkitTextStrokeColor: "#E6B325",
  },
}));
function SubTitle(props: subTitleProps) {
  const classes = useStyles();
  return (
    <Typography variant="h2" fontSize={{xs:'35px',md:'65px'}}  className={classes.classes.root}>
      {props.title}
    </Typography>
  );
}

export default SubTitle;
