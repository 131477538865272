import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import useStyles from "./styles";
import { Link } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
const pagesLeft = [
  { name: "HOME", url: "/" },
  { name: "INVENTORY", url: "/inventory" },
  { name: "BUY CAR", url: "/buycar" },
  { name: "SELL CAR", url: "/sellcar" },
  { name: "ABOUT US", url: "/aboutus" }
];
const pagesRight = [
  { name: "HAPPY CLIENTS", url: "/clients" },
  { name: "CONTACT US", url: "/contactus" },
];



const NavBarOther = () => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  // const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
  //   null
  // );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  // const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorElUser(event.currentTarget);
  // };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  // const handleCloseUserMenu = () => {
  //   setAnchorElUser(null);
  // };

  const classes = useStyles();

  return (
    <AppBar position="relative"  sx={{color:"#121212"}} elevation={0}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Container
            sx={{
              display: { xs: "flex", md: "none" },
              justifyContent: "space-between",
              alignItems: "center",
              minWidth: "100%",
            }}
          >
            <Link to={"/"}>
              <img src="/logo.png" height={"70px"} />
            </Link>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              sx={{ color: "#fff" }}
            >
              <MenuIcon sx={{fontSize:"50px"}}/>
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none", width: "100%" },
              }}
            >
              {pagesLeft.map((page) => (
                <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                  <Link to={page.url}>
                    <Typography textAlign="center">{page.name}</Typography>
                  </Link>
                </MenuItem>
              ))}

              {pagesRight.map((page) => (
                <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                  <Link to={page.url}>
                    <Typography textAlign="center">{page.name}</Typography>
                  </Link>
                </MenuItem>
              ))}
            </Menu>
          </Container>

          {/* MidDevice  */}
          <Container maxWidth="xl">
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                justifyContent: "space-between",
                alignItems: "center",
                fontSize: { md: "10px", sm: "12px", lg: "25px" },
              }}
            >
              <Link to={"/"}>
                <img src="/logo.png" height={"100px"} />
              </Link>
              <Box display={"flex"}>
                {pagesLeft.map((page, id) => (
                  <Link to={page.url} key={id}>
                    <Button
                      key={page.name}
                      onClick={handleCloseNavMenu}
                      sx={{
                        p: 2,
                        color: "white",
                        display: "block",
                        minWidth: {lg:"150px",md:"100px"},
                        fontSize: "22px",
                      }}
                    >
                      {page.name}
                    </Button>
                  </Link>
                ))}
                {pagesRight.map((page, id) => (
                  <Link to={page.url} key={id}>
                    <Button
                      key={page.name}
                      onClick={handleCloseNavMenu}
                      sx={{
                        p: 2,
                        color: "white",
                        display: "block",
                        minWidth: {lg:"150px",md:"100px"},
                        fontSize: "22px",
                      }}
                    >
                      {page.name}
                    </Button>
                  </Link>
                ))}
              </Box>
            </Box>
          </Container>
          {/* End MiDDevices  */}
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default NavBarOther;
