import { Route, Routes } from "react-router-dom";
import React from "react";
import Home from "./pages/Home";
import ContactUs from "./pages/ContactUs";
import AboutUs from "./pages/AboutUs";
import BuyCar from "./pages/BuyCar";
import ViewCar from "./pages/ViewCar";
import Inventory from "./pages/Inventory";
import SellCar from "./pages/SellCar";
import TestimonialPage from "./pages/TestimonialPage";

const RoutesPath = () => {
  return (
    <React.Fragment>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/contactus" element={<ContactUs />}></Route>
        <Route path="/sellcar" element={<SellCar />}></Route>
        <Route path="/aboutus" element={<AboutUs />}></Route>
        <Route path="/buycar" element={<BuyCar />}></Route>
        <Route path="/inventory" element={<Inventory />}></Route>
        <Route path="/clients" element={<TestimonialPage />}></Route>
        <Route path="/buycar/:id" element={<ViewCar />}></Route>
      </Routes>
    </React.Fragment>
  );
};
export default RoutesPath;
