import { Box } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import AboutUsDetails from "../sections/@AboutUs";
import Page from "../components/Page";
import NavBarOther from "../components/Layout/NarBarOther";

function AboutUs() {
  useEffect(() => {
    console.log("aboutus us call");
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <Page
      title="About Us:Progressive auto sports"
      description="here is an description"
    >
      <NavBarOther/>
      <AboutUsDetails />
    </Page>
  );
}

export default AboutUs;
