import { Box } from '@mui/material';
import React from 'react';
import MainBox from '../../core/MainBox';
import MainFooter from './MainFooter';


function Footer() {
  return (
    <Box  sx={{background:'#000',height:'100%'}} >
        <MainFooter />
    </Box>
  )
}

export default Footer;