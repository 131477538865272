import { Box, Divider, Typography } from "@mui/material";
import React from "react";

const Mission = () => {
  return (
    <Box p={2}>
      <Typography
        variant="h3"
        gutterBottom
        sx={{ fontWeight: "bold", color: "#060606", fontSize: "24px" }}
      >
        Our Mission{" "}
      </Typography>
      <Typography fontWeight={"500"}>
      Our mission is simple; to provide our clients the meaning of LUXURY. We have been blessed with 100+ best pre-owned elite cars and have year-by-year been a mascot for the same. Our cars look better than those parked in the company showrooms, but are much easier to buy. We make sure that our clients get satisfied with both, the car's condition and its cost.
      </Typography>
    </Box>
  );
};

export default Mission;
