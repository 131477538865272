import { Box, Typography } from "@mui/material";
import React from "react";

const Heading = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          height: { sm: "350px", xs: "250px", md: "350px",lg:"450" },
          width: { xs: "100%", sm: "100%", md: "100%" },
          py:{lg:10,sm:3,xs:3.5}
        }}
      >
        <Typography
          variant="h3"
          sx={{
            fontSize: { xs: "25px", sm: "40px", md: "60px" },
            pb: { md: 5, sm: 2 },
            fontWeight: "bold",
            textAlign: { sm: "center", xs: "center", md: "start" },
          }}
        >
          Welcome to the world of
        </Typography>
        <Typography
          variant="h1"
          sx={{
            fontSize: { xs: "30px", sm: "60px", md: "70px" },
            fontWeight: "bolder",
            textAlign: { sm: "center", xs: "center", md: "start" },
          }}
        >
          Progressive Auto Sports
        </Typography>
      </Box>
    </Box>
  );
};

export default Heading;
