import { Typography } from "@mui/material";
import React from "react";
import { makeStyles } from "tss-react/mui";

interface titleProps {
  title: string | undefined,
  color?:string
}
const useStyles = makeStyles()((theme) => ({
  root: {
    color: "#121212",
  },
}));
function Title(props: titleProps) {
  const classes = useStyles();

  return (
    <Typography variant="h2" fontSize={{xs:'25px',md:'60px'}} lineHeight={{xs:'40px',sm:'40px',md:'80px'}}  color={props.color?props.color:'#121212'} >
      {props.title}
    </Typography>
  );
}

export default Title;
