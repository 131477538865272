import React from "react";
import Footer from "./components/Footer";
import RoutesPath from "./Routes";

function App() {
  return (
    <>
      {/* <NavBar /> */}
      <RoutesPath />
      <Footer />
    </>
  );
}

export default App;
