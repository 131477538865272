import React from "react";
import { Box, Typography } from "@mui/material";

interface cardProps {
  title?: string;
  subTitle?: string;
  children?: React.ReactNode;
}
const Card = (props: cardProps) => {
  return (
    <React.Fragment>
      <Box
        sx={{
          boxShadow:
            "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
          borderRadius: "10px",
          width: "auto",
        }}
      >
        {props.children}
      </Box>
      <Box p={2}>
        <Typography variant="subtitle1" sx={{textAlign:'left'}}>{props.title}</Typography>
        <Typography variant="subtitle2" sx={{textAlign:'left'}}>{props.subTitle}</Typography>
      </Box>
    </React.Fragment>
  );
};
export default Card;
