import React from "react";
import Banner from "../../components/Banner";
import {
  Brands,
  CarType,
  PlanForSell,
  Works,
  PopularCar,Testimonial  
} from "../../components/homeComponents";
import ContactDetails from "../@ContactUs";

const HomeDetails = () => {
  return (
    <React.Fragment>
      <Banner />
      <PlanForSell />
      <Brands />
      <CarType />
      <Works />
      <PopularCar />
      <Testimonial />
      <ContactDetails />
    </React.Fragment>
  );
};

export default HomeDetails;
