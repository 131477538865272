import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Typography,
  TextField,
} from "@mui/material";
import React from "react";
import Slider from "react-slick";
import Between from "../core/Between";
import Center from "../core/Center";
import Img from "../core/Img";
import CardTitle from "../core/CardTitle";
import ClipButton from "../core/ClipButton";
import OutLineButton from "../core/OutLineButton";
import Modal from "@mui/material/Modal";
import { Link, useParams } from "react-router-dom";
import productList from "../assets/carDetails/productList.json";
import { InputAdornment } from "@mui/material";
import { useState } from "react";
import { NextBtn, PreviousBtn } from "../components/Slick";
import NavBarOther from "../components/Layout/NarBarOther";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { md: 500, sm: 400, xs: 280 },
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: { md: 4, sm: 2, xs: 2 },
};

const ViewCar = () => {
  const [loanAmount, setLoanAmount] = useState(0);
  const [interestRate, setInterestRate] = useState(0);
  const [numMonths, setNumMonth] = useState(0);
  const [monthlyPayment, setMonthlyPayment] = useState(0);

  const calculate = () => {
    setMonthlyPayment((+loanAmount * (1 + +interestRate / 100)) / +numMonths);
    
  };

  const [imgNumber, setImgNumber] = useState(2);

  // const calculateEmiHandler = () => {
  //   // console.log(month);/
  // };

  type datainterface = {
    "BRAND ": string;
    IMAGE_FOLDER: string;
    TOTAL_IMAGE: number;
    "VERIENT ": string;
    "REGT. NO.": string;
    YEAR: number;
    COLOR: string;
    OWNER: number;
    FUEL: string;
    "KM / MLS": number;
    "INSURANCE ": string;
    "PRICE ": number | string;
    id: number;
  };

  const [data, setData] = React.useState<datainterface>();

  const imgShowHandler = (index: number) => {
    setImgNumber(index + 1);
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  console.log("Product List is", productList);

  const settings = {
    prevArrow: <PreviousBtn />,
    nextArrow: <NextBtn />,
    infinite: true,
    slidesToShow: 4,
    speed: 500,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  const { id } = useParams();
  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    if (id !== undefined) {
      const dataHand = productList.filter((List) => {
        for (let i = 0; i < productList.length; i++) {
          if (+id === List.id) {
            return true;
          }
        }
      });
      console.log("CAR Data is", dataHand);
      setData(dataHand[0]);
    }
  }, []);

  console.log("Data Id is ", data);

  return (
    <React.Fragment>
      <NavBarOther />
      <Grid
        container
        sx={{
          position: "relative",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <Grid
          item
          md={0}
          sm={12}
          xs={12}
          sx={{ display: { md: "none", sm: "block", xs: "none" } }}
        >
          <Box p={1}>
            <Slider {...settings}>
              {[...Array(data?.TOTAL_IMAGE)].map((x, i) => (
                <Center height={"100%"} key={i}>
                  {data?.TOTAL_IMAGE === i ? (
                    <Img
                      imgUrl={`/${data?.IMAGE_FOLDER}/${i + 1}.jpeg`}
                      height={"250px"}
                      borderRadius={"5px"}
                    />
                  ) : (
                    <Img
                      imgUrl={`/${data?.IMAGE_FOLDER}/${++i}.jpeg`}
                      height={"250px"}
                      borderRadius={"5px"}
                    />
                  )}
                </Center>
              ))}
            </Slider>
          </Box>
        </Grid>


        <Grid
          item
          md={0}
          sm={12}
          xs={12}
          sx={{ display: { md: "none", sm: "none", xs: "block" } }}
        >
          <Box p={1}>
            <Slider {...settings}>
              {[...Array(data?.TOTAL_IMAGE)].map((x, i) => (
                <Center height={"100%"} key={i}>
                  {data?.TOTAL_IMAGE === i ? (
                    <Img
                      imgUrl={`/${data?.IMAGE_FOLDER}/${i + 1}.jpeg`}
                      height={"150px"}
                      width={"auto"}
                      borderRadius={"5px"}
                    />
                  ) : (
                    <Img
                      imgUrl={`/${data?.IMAGE_FOLDER}/${++i}.jpeg`}
                      height={"150px"}
                      width={"auto"}
                      borderRadius={"5px"}
                    />
                  )}
                </Center>
              ))}
            </Slider>
          </Box>
        </Grid>

        <Grid item md={4} sm={12} xs={12} p={{ md: 5 }}>
          <Card sx={{ background: "#f6f6f6" }}>
            <CardTitle
              title={data?.["VERIENT "] ? data?.["VERIENT "] : ""}
              subtitle={data?.["BRAND "]}
            />
            <Divider sx={{ border: "none" }} />
            <Between py={2}>
              <Typography variant="subtitle1"> Details</Typography>
              <ClipButton title={`${data?.YEAR}`} />
            </Between>
            <Between px={2}>
              <Center>
                <Box display={"flex"}>
                  <Typography
                    sx={{
                      color: "#000",
                      fontWeight: "bold",
                      fontSize: 15,
                    }}
                  >
                    FUEL TYPE: {data?.FUEL}
                  </Typography>
                </Box>
              </Center>
              <Center>
                <Box display={"flex"}>
                  <Typography
                    sx={{
                      color: "#000",
                      px: 0,
                      fontWeight: "bold",
                      fontSize: 14,
                    }}
                  >
                    KM/Miles: {data?.["KM / MLS"]}
                  </Typography>
                </Box>
              </Center>
            </Between>
            <Between px={2}>
              <Center>
                <Box display={"flex"}>
                  <Typography
                    sx={{
                      color: "#000",
                      fontWeight: "bold",
                      fontSize: 15,
                    }}
                  >
                    BODY COLOR : {data?.COLOR}
                  </Typography>
                </Box>
              </Center>

              <Center>
                <Box display={"flex"}>
                  <Typography
                    sx={{
                      color: "#000",
                      px: 1,
                      fontWeight: "bold",
                      fontSize: 15,
                    }}
                  >
                    OWNER : {data?.OWNER}
                  </Typography>
                </Box>
              </Center>
            </Between>
            <Between p={2}>
              <Center>
                <Button
                  variant="outlined"
                  sx={{ background: "#000", color: "#fff" }}
                  onClick={handleOpen}
                >
                  Calulate EMI
                </Button>
              </Center>
            </Between>

            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              sx={{ zIndex: 9999999 }}
            >
              <Box sx={style}>
                {window.innerWidth > 425 && (
                  <Center padding={3}>
                    <Img
                      imgUrl={`/${data?.IMAGE_FOLDER}/2.jpeg`}
                      height={"200px"}
                    />
                  </Center>
                )}

                <Between px={2}>
                  <Center>
                    <CardTitle
                      title={data?.["VERIENT "]}
                      // subtitle={"" + data?.["PRICE "]}
                    />
                  </Center>
                  <Center>
                    <ClipButton title={`${data?.YEAR}`} />
                  </Center>
                </Between>
                <Box p={1}>
                  <Typography>Loan Amount</Typography>
                  <TextField
                    fullWidth
                    type={"number"}
                    variant="outlined"
                    value={loanAmount === 0 ? "" : loanAmount}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setLoanAmount(+e.target.value);
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Typography>Rs.</Typography>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>

                <Between p={1}>
                  <Box width={"40%"}>
                    <Typography>Interest Rate</Typography>
                    <TextField
                      fullWidth
                      type={"number"}
                      variant="outlined"
                      inputProps={{ min: 0, max: 100 }}
                      value={interestRate === 0 ? "" : interestRate}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setInterestRate(+e.target.value);
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography>%</Typography>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <Box width={"40%"}>
                    <Typography>Total Month</Typography>
                    <TextField
                      fullWidth
                      type={"number"}
                      variant="outlined"
                      value={numMonths === 0 ? "" : numMonths}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setNumMonth(+e.target.value);
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography>Month</Typography>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Between>

                <Button
                  variant="outlined"
                  sx={{ background: "#000", color: "#fff" }}
                  onClick={calculate}
                >
                  Calulate EMI
                </Button>

                <Between px={1} py={1}>
                  <Center>
                    <Typography
                      id="modal-modal-title"
                      sx={{ color: "#000" }}
                      variant="h5"
                      component="h4"
                    >
                      Monthly Payment is
                    </Typography>
                  </Center>
                  <Center>
                    <Typography
                      id="modal-modal-title"
                      sx={{ color: "#000" }}
                      variant="h5"
                      component="h4"
                    >
                      {monthlyPayment.toFixed(2)}
                    </Typography>
                  </Center>
                </Between>

                <Between px={1} py={1}>
                  <Center>
                    <Typography
                      id="modal-modal-title"
                      sx={{ color: "#000" }}
                      variant="h5"
                      component="h4"
                    >
                      Total Interest Payment
                    </Typography>
                  </Center>
                  <Center>
                    <Typography
                      id="modal-modal-title"
                      sx={{ color: "#000" }}
                      variant="h5"
                      component="h4"
                    >
                      {((monthlyPayment*numMonths)-(loanAmount)).toFixed(2)}
                    </Typography>
                  </Center>
                </Between>

                {/*
                <Between px={5}>
                  <Center>
                    <Typography
                      id="modal-modal-title"
                      sx={{ color: "#000" }}
                      variant="h5"
                      component="h4"
                    >
                      Total Amount Payment
                    </Typography>
                  </Center>
                  <Center>
                    <Typography
                      id="modal-modal-title"
                      sx={{ color: "#000" }}
                      variant="h5"
                      component="h4"
                    >
                      $481000
                    </Typography>
                  </Center>
                </Between>

                <Box p={3}>
                  <Between>
                    <Center>
                      <CardTitle title="EMI" subtitle="Monthly Payment" />
                    </Center>
                    <Center>
                      <CardTitle
                        title="$200"
                        subtitle="Calculated on On Road Price"
                      />
                    </Center>
                  </Between>
                </Box> */}
              </Box>
            </Modal>

            <Divider sx={{ py: 1 }} />
            <Box p={3}>
              <Between>
                <Typography
                  variant="subtitle1"
                  fontSize={16}
                  fontWeight={"bold"}
                >
                  Registration year
                </Typography>
                <Typography
                  variant="subtitle1"
                  fontSize={16}
                  fontWeight={"bolder"}
                >
                  {data?.YEAR}
                </Typography>
              </Between>
              <Between>
                <Typography
                  variant="subtitle1"
                  fontSize={16}
                  fontWeight={"bold"}
                >
                  Registration state
                </Typography>
                <Typography
                  variant="subtitle1"
                  fontSize={16}
                  fontWeight={"bolder"}
                >
                  {data?.["REGT. NO."]}
                </Typography>
              </Between>
              {/* <Between>
                <Typography
                  variant="subtitle1"
                  fontSize={16}
                  fontWeight={"bold"}
                >
                  Interior Colors
                </Typography>
                <Typography
                  variant="subtitle1"
                  fontSize={16}
                  fontWeight={"bolder"}
                >
                  White
                </Typography>
              </Between> */}
              <Between>
                <Typography
                  variant="subtitle1"
                  fontSize={16}
                  fontWeight={"bold"}
                >
                  Insurance
                </Typography>
                <Typography
                  variant="subtitle1"
                  fontSize={16}
                  fontWeight={"bolder"}
                >
                  {data?.["INSURANCE "]}
                </Typography>
              </Between>
              {/* <Between>
                <Typography
                  variant="subtitle1"
                  fontSize={16}
                  fontWeight={"bold"}
                >
                  Horsepower (hp)
                </Typography>
                <Typography
                  variant="subtitle1"
                  fontSize={16}
                  fontWeight={"bolder"}
                >
                  150
                </Typography>
              </Between> */}
              <Between>
                <Typography
                  variant="subtitle1"
                  fontSize={16}
                  fontWeight={"bold"}
                >
                  Price
                </Typography>
                <Typography
                  variant="subtitle1"
                  fontSize={16}
                  fontWeight={"bolder"}
                >
                  {data?.["PRICE "] + ""}
                </Typography>
              </Between>
            </Box>
            <Divider sx={{ py: 1, border: "none" }} />
            <Box textAlign={"center"}>
              <Link to={"/contactus"}>
                <OutLineButton text="Buy Now" />
              </Link>
            </Box>
          </Card>
        </Grid>
        <Grid
          item
          sm={12}
          md={6}
          sx={{ display: { md: "block", sm: "none", xs: "none" } }}
        >
          <Box>
            <Center height={"auto"}>
              {imgNumber == 1 || imgNumber == 3 ? (
                <Img
                  imgUrl={`/${data?.IMAGE_FOLDER}/${imgNumber}.jpeg`}
                  height={"300px"}
                  width={"100%"}
                  borderRadius={"20px"}
                />
              ) : (
                <Img
                  imgUrl={`/${data?.IMAGE_FOLDER}/${imgNumber}.jpeg`}
                  height={"300px"}
                  // width={"100%"}
                  borderRadius={"20px"}
                />
              )}
            </Center>
          </Box>

          <Box
            display={"flex"}
            sx={{
              height: "100%",
              width: "100%",
              position: "relative",
              display: { md: "block", sm: "none", xs: "none" },
            }}
          >
            <Slider {...settings}>
              {[...Array(data?.TOTAL_IMAGE)].map((x, i) => (
                <Center padding={2} height="100%" key={i}>
                  {
                    <Box height="100%" onMouseOver={() => imgShowHandler(i)}>
                      <Img
                        imgUrl={`/${data?.IMAGE_FOLDER}/${i + 1}.jpeg`}
                        height={"100px"}
                        width={"100%"}
                        borderRadius={"5px"}
                        boxShadow={
                          i + 1 === imgNumber
                            ? "rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px"
                            : ""
                        }
                      />
                    </Box>
                  }
                </Center>
              ))}
            </Slider>
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default ViewCar;
