import Chip from "@mui/material/Chip";
import React from "react";
interface ClipInteface {
  title?: string | number | undefined;
  
}
const ClipButton = (props: ClipInteface) => {
  return (
    <React.Fragment>
      <Chip label={"Model :"+props.title} sx={{background:'#000',color:'#FFD369'}} />
    </React.Fragment>
  );
};
export default ClipButton;
