import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
const NextBtn = (props: any) => {
    const { className, onClick, slideCount, currentSlide } = props;
    return (
      <>
        <div className={className} onClick={onClick}>
          <ArrowForwardIosIcon
            style={{
              color: "#F6D368",
              fontSize: "25px",
              borderRadius: "2px",
              background: "none",
            }}
          />
        </div>
      </>
    );
  };
  export default NextBtn