import React from "react";
import Page from "../components/Page";
import HomeDetails from "../sections/@Home";
import NavBar from "../components/Layout/NavBar";
function Home() {
  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <Page title="Progressive Auto Sports" description="here is an description">
      <NavBar />
      <HomeDetails />
    </Page>
  );
}

export default Home;
