import { Box, Typography } from "@mui/material";
import React from "react";

const Details = () => {
  return (
    <Box p={2}>
      <Typography
        variant="h3"
        gutterBottom
        sx={{ fontWeight: "bold", color: "#060606", fontSize: "20px" }}
      >
        Progressive Auto Sports
      </Typography>
      <Typography
        variant="h3"
        gutterBottom
        sx={{ fontWeight: "bold", color: "#060606", fontSize: "24px" }}
      >
        A pre-owned luxury car dealer deals with 24 brands{" "}
      </Typography>

      <Typography fontWeight={"500"}>
        Progressive Autosport has been a role model in the pre owned elite cars
        industry since it's debut in the year 2009. We deal with the very best
        high end cars for people who look upto luxury. Over the years, we have
        created such havoc, that our clients' repeatability is unmatchable.
        Nobody comes close to us when it comes to WE BELIEVE IN LUXURY.
      </Typography>
    </Box>
  );
};

export default Details;
