import { Grid, Box, Typography } from "@mui/material";
import React from "react";
import SubTitle from "../../core/SubTitle";
import Title from "../../core/Title";
import useStyles from "./styles";
import Center from "../../core/Center";
import Img from "../../core/Img";
const Works = () => {
  const classes = useStyles();
  return (
    <section>
      <React.Fragment>
        <Box
          sx={{
            background: "#000",
          }}
        >
          <Grid container className={classes.classes.center}>
            <Grid item md={10} sm={12} sx={{ width: "100%", p: 2 }}>
              <Box>
                <Title title="How its works" color={"#fff"}></Title>
                <SubTitle title="Buying used luxury cars" />
              </Box>
            </Grid>
            <Grid item md={10} sm={12}>
              <Grid container py={{ md: 5 }} sx={{ width: "100%" }}>
                <Grid item md={3} sm={12} xs={12} py={{ xs: 5 }}>
                  <Center>
                    <Img
                      imgUrl="https://cdn.bigboytoyz.com/new-version/assets/images/howitworks-img1.png"
                      height="180px"
                    />
                  </Center>
                  <Center>
                    <Typography color={"#fff"} variant={"h5"} pt={1}>
                      Shortlist your favourites from our collection
                    </Typography>
                  </Center>
                </Grid>
                <Grid item md={3} sm={12} xs={12} py={{ xs: 5 }}>
                  <Center>
                    <Img
                      imgUrl="https://cdn.bigboytoyz.com/new-version/assets/images/howitworks-img2.png"
                      height="180px"
                    />
                  </Center>
                  <Center>
                    <Typography
                      color={"#fff"}
                      textAlign={"center"}
                      variant={"h5"}
                      pt={1}
                    >
                      Check the details of your cars and see if they suit you
                    </Typography>
                  </Center>
                </Grid>
                <Grid item md={3} sm={12} xs={12} py={{ xs: 5 }}>
                  <Center>
                    <Img
                      imgUrl="https://cdn.bigboytoyz.com/new-version/assets/images/howitworks-img3.png"
                      height="180px"
                    />
                  </Center>
                  <Center>
                    <Typography color={"#fff"} variant={"h5"} pt={1}>
                      Book your dream luxury car
                    </Typography>
                  </Center>
                </Grid>

                <Grid item md={3} sm={12} xs={12} py={{ xs: 5 }}>
                  <Center>
                    <Img
                      imgUrl="https://cdn.bigboytoyz.com/new-version/assets/images/howitworks-img4.png"
                      height="180px"
                    />
                  </Center>
                  <Center>
                    <Typography color={"#fff"} variant={"h5"} pt={1}>
                      Instant payment option for your dream luxury car
                    </Typography>
                  </Center>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </React.Fragment>
    </section>
  );
};

export default Works;
