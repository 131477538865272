import { Box, Typography, Grid, Button } from "@mui/material";
import React from "react";
import Slider from "react-slick";
import Img from "../../core/Img";
import { NextBtn, PreviousBtn } from "../Slick";
import Center from "../../core/Center";
import Modal from "@mui/material/Modal";

const Gallary = () => {
  const settings = {
    prevArrow: <PreviousBtn />,
    nextArrow: <NextBtn />,
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "0px",
    slidesToShow: 3,
    speed: 500,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  const [imgUrl, setImgUrl] = React.useState<number>(0);

  const [open, setOpen] = React.useState(false);
  const handleOpen = (id: number) => {
    console.log("inner Width", window.innerWidth);
    if (window.innerWidth > 425) {
      setImgUrl(id);
      setOpen(true);
    } else {
      setImgUrl(0);
      setOpen(false);
    }
  };
  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { md: 550, sm: "100%" },

    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 1,
  };

  return (
    <>
      <Box p={2}>
        <Typography
          variant="h1"
          gutterBottom
          sx={{ fontWeight: "bold", color: "#060606", fontSize: "24px" }}
        >
          Our Happy Clients{" "}
        </Typography>
      </Box>
      <Box p={1}>
        <Grid
          container
          sx={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Grid
            item
            md={10}
            sm={12}
            xs={12}
            sx={{ height: "100%", padding: { xs: "20px", md: 5, sm: 0 } }}
          >
            <Grid
              container
              sx={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <Slider {...settings}>
                <Center>
                  <Button onClick={() => handleOpen(1)}>
                    <Img imgUrl="./testimonial_Img/1.jpeg" height="300px"></Img>
                  </Button>
                </Center>

                <Center>
                  <Button onClick={() => handleOpen(2)}>
                    <Img imgUrl="./testimonial_Img/2.jpeg" height="300px"></Img>
                  </Button>
                </Center>

                <Center>
                  <Button onClick={() => handleOpen(3)}>
                    <Img imgUrl="./testimonial_Img/3.jpeg" height="300px"></Img>
                  </Button>
                </Center>
                <Center>
                  <Button onClick={() => handleOpen(4)}>
                    <Img imgUrl="./testimonial_Img/4.jpeg" height="300px"></Img>
                  </Button>
                </Center>
                <Center>
                  <Button onClick={() => handleOpen(5)}>
                    <Img imgUrl="./testimonial_Img/5.jpeg" height="300px"></Img>
                  </Button>
                </Center>
                <Center>
                  <Button onClick={() => handleOpen(6)}>
                    <Img imgUrl="./testimonial_Img/6.jpeg" height="300px"></Img>
                  </Button>
                </Center>
                <Center>
                  <Button onClick={() => handleOpen(7)}>
                    <Img imgUrl="./testimonial_Img/7.jpeg" height="300px"></Img>
                  </Button>
                </Center>
              </Slider>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Center>
            <img
              src={`./testimonial_Img/${imgUrl}.jpeg`}
              width={"100%"}
              height="100%"
            ></img>
          </Center>
        </Box>
      </Modal>
    </>
  );
};

export default Gallary;
