import { FacebookRounded, Instagram } from "@mui/icons-material";
import { Box } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
type socialMedaiTye={
    mode?:boolean
}
const SocialMedai = ({mode}:socialMedaiTye) => {
  return (
    <Box>
      <Link
        to="#"
        onClick={(e) => {
          window.location.href =
            "https://www.facebook.com/progressiveautosports";
          e.preventDefault();
        }}
      >
        <FacebookRounded
          sx={{
            fontSize: "30px",
            color: !mode?"#fff":"#000",
            fontWeight: "500",
            mx: 2,
          }}
        />
      </Link>
      <Link
        to="#"
        onClick={(e) => {
          window.location.href =
            "https://www.instagram.com/progressive.auto.sports/";
          e.preventDefault();
        }}
      >
        <Instagram
          sx={{
            fontSize: "30px",
            color: !mode?"#fff":"#000",
            fontWeight: "500",
            mx: 2,
          }}
        />
      </Link>
      <Link
        to="#"
        onClick={(e) => {
          window.location.href =
            "mailto:contactprogressiveautosports@gmail.com";
          e.preventDefault();
        }}
      >
        <MailOutlineOutlinedIcon
          sx={{
            fontSize: "30px",
            color: !mode?"#fff":"#000",
            fontWeight: "500",
            mx: 2,
          }}
        />
      </Link>
    </Box>
  );
};

export default SocialMedai;
