import { Typography, Box } from "@mui/material";
import React from "react";
interface CartTitleProps {
  title: string | undefined;
  subtitle?: string | number |undefined;
}
const CartTitle = (props: CartTitleProps) => {
  return (
    <Box>
      <Typography variant="h3" color={"#251B37"} sx={{fontWeight:'bold',fontSize:'20px',m:0,p:0}}>
        {props.title}
      </Typography>
      <Typography variant="h4" color={"#372948"} sx={{fontWeight:'bold',fontSize:'15px',m:0,p:0}}>
        {props.subtitle}
      </Typography>
    </Box>
  );
};
export default CartTitle;
