import { Grid, Box, Button } from "@mui/material";
import React from "react";
import Slider from "react-slick";
import "./style.css";
import Title from "../../core/Title";
import SubTitle from "../../core/SubTitle";
import sliderCar from "../../assets/carDetails/sliderCar.json";
import { NextBtn, PreviousBtn } from "../Slick";
import Between from "../../core/Between";
import Center from "../../core/Center";
import ClipButton from "../../core/ClipButton";
import CartTitle from "../../core/CardTitle";
import { Link } from "react-router-dom";
import Img from "../../core/Img";

function PopularCar() {
  const settings = {
    prevArrow: <PreviousBtn />,
    nextArrow: <NextBtn />,
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "0px",
    slidesToShow: 3,
    speed: 500,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  console.log("sliderCar",sliderCar)
  return (
    <Box
      p={{ md: 5, sm: 3, xs: 2 }}
      sx={{ height: { md: "100vh", sm: "100%" }, justifyContent: "center",alignItems:"center" }}
    >
      <Grid
        container
        sx={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <Grid item md={10} sm={12}>
          <Box py={{ md: 10 }}>
            <Title title="popular Car at "></Title>
            <SubTitle title="Progressive Auto Sports"></SubTitle>
          </Box>
        </Grid>
      </Grid>

      <Grid
        container
        sx={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
        }}
        
      >
        {/* py={{ xs: 5, md: 0, sm: 2 }}   */}
        <Grid item md={10} sm={12} xs={12}   >
          <Slider {...settings}>
            {sliderCar.map((data) => (
              <Link to={`/buycar/${data.id}`} key={data.id}>

                <Box
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                  }}
                  key={data.id}
                >
                  <Box
                    display={{ md: "flex", sm: "flex", xs: "flex" }}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Box sx={{ background: "#e6e6e6", borderRadius: "10px" }}>
                      <Box sx={{ position: "relative" }}>
                        {!data.STATUS && (
                          <Button
                            size={"small"}
                            sx={{
                              position: "absolute",
                              background: "#CF0A0A",
                              color: "#fff",
                              border: "none",
                              fontWeight: "bolder",
                              borderBottomRightRadius: "20px",
                            }}
                          >
                            Sold Out
                          </Button>
                        )}
                        <Img
                          imgUrl={`/${data.IMAGE_FOLDER}/2.jpeg`}
                          height={"250px"}
                          width={"100%"}
                          borderRadius={"5px"}
                        />
                      </Box>
                      {/* <img
                      src={`/${data.IMAGE_FOLDER}/2.jpeg`}
                      height={"250px"}
                      width={"100%"}
                      style={{ borderRadius: "10px" }}
                    /> */}
                      <Between px={2} py={1.5}>
                        <Center>
                          <CartTitle
                            title={data["BRAND "]}
                            subtitle={data["VERIENT "]}
                          />
                        </Center>
                        <Center>
                          <ClipButton title={` ${data.YEAR}`} />
                        </Center>
                      </Between>
                    </Box>
                  </Box>
                </Box>
              </Link>
            ))}
          </Slider>
        </Grid>
      </Grid>
    </Box>
  );
}

export default PopularCar;
