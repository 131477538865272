import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
const PreviousBtn = (props: any) => {
  const { className, onClick, currentSlide } = props;
  return (
    <>
      <div className={className} onClick={onClick}>
        <ArrowBackIosNewIcon
          sx={{
            color: "#F6D368",
            fontSize: "25px",
            borderRadius: "2px",
            background: "none",
          }}
        />
      </div>
    </>
  );
};
export default PreviousBtn;
