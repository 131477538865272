import React from "react";
import { Box, Typography } from "@mui/material";

const Title = (props:{title?:string,subTitle?:string}) => {
  return (
    <Box py={{ md: 4, sm: 2, xs: 2 }}>
      <Typography
        variant="h3"
        sx={{
          fontWeight: "bolder",
          color: "#B2B2B2",
          fontSize: "35px",
          paddingLeft: "1rem",
        }}
      >
        {props.title}
      </Typography>
      <Typography
        variant="h4"
        sx={{
          fontWeight: "bolder",
          color: "#000",
          fontSize: "20px",
          paddingLeft: "1rem",
        }}
      >
        {props.subTitle}
      </Typography>
    </Box>
  );
};

export default Title;
