import React from "react";
import Page from "../components/Page";
import TestimonialComponents from "../components/testimonialComponents";
import NavBarOther from "../components/Layout/NarBarOther";

const TestimonialPage = () => {
  return (
    <Page
    title="Testimonial : Progressive auto sports"
    description="here is an description"
  >
    <NavBarOther/>
    <TestimonialComponents />
  </Page>
  )
}

export default TestimonialPage