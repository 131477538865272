import { Box, Button } from "@mui/material";
import NavigateNextTwoToneIcon from "@mui/icons-material/NavigateNextTwoTone";
interface OutLineButtonProps {
  text: string;
  color?:string;
  borderColor?:string
}
const OutLineButton = (props: OutLineButtonProps) => {
  return (
    <Box p={2}>
      <Button
        variant="outlined"
        fullWidth
        endIcon={<NavigateNextTwoToneIcon />}
        sx={{ width: {md:'60%'} , p: 2, justifyContent: "space-between",color:props.color,borderColor:props.borderColor }}
      >
        {props.text}
      </Button>
    </Box>
  );
};
export default OutLineButton;
