import React from "react";
import { Box } from "@mui/system";
import { Grid, Typography } from "@mui/material";
import SocialMedai from "./socialMedai";

const Address = () => {
  return (
    <Grid container p={2}>
      {/* <Box
        sx={{
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
          height:"100%"
        }}
      >
        <Typography
          variant="h4"
          sx={{
            color: "#6B728E",
            fontWeight: "bolder",
            fontSize: "30px",
            p: 2,
          }}
        >
          Our Showroom
        </Typography>
      </Box> */}
      <Grid item xs={12}>
        <Typography
          variant="h4"
          sx={{
            color: "#B2B2B2",
            fontWeight: "bolder",
            fontSize: "25px",
          }}
        >
          Our Showroom
        </Typography>
      </Grid>
      <Grid item xs={12} px={1}>
        <Box py={2}>
          <Typography
            sx={{
              color: "#000",
              fontSize: "20px",
            }}
          >
            Delhi Location
          </Typography>

          <Typography py={1}>
            Plot No - 82, Patparganj Industrial Area,
            <br /> Near Anand Vihar, Delhi, 110092.
          </Typography>
        </Box>
      </Grid>
      <Grid item md={12}>
            <SocialMedai/>
      </Grid>
    </Grid>
  );
};

export default Address;
