import { Box } from "@mui/material";
import React from "react";
import Form from "../components/Form";
import { useEffect } from "react";
import Page from "../components/Page";
import ContactDetails from "../sections/@ContactUs";
import NavBarOther from "../components/Layout/NarBarOther";

function ContactUs() {
  useEffect(() => {
    console.log("contact us us call");
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
  
    <Page title="Progressive Auto Sports" description="here is an description">
      <NavBarOther/>
      <ContactDetails/>
    </Page>
  );
}

export default ContactUs;