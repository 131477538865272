import { Box, Grid } from "@mui/material";
import { Details, Gallary, Mission } from "../../components/aboutUsComponents";
import Img from "../../core/Img";
import carSel from "../../assets/images/showRoomPhoto.jpeg";
import Center from "../../core/Center";
import OurShowroom from "../../components/aboutUsComponents/ourShowroom";

const AboutUsDetails = () => {
  return (
    <Box
      sx={{
        position: "relative",
        height: { md: "100%", sm: "100%", xs: "100%" },
        justifyContent: "center",
        backgroundColor: "#fff",
      }}
    >
      <Grid
        container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: "#fff",
        }}
      >
        <Grid item md={10} sm={12} xs={12} py={3}>
          <Details />
          <Mission />
        </Grid>
      </Grid>

      <Grid
        container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: "#fff",
        }}
      >
        <Grid item md={10} sm={12} xs={12} py={3}>
          <Gallary />
        </Grid>
      </Grid>
      <OurShowroom />
    </Box>
  );
};

export default AboutUsDetails;
