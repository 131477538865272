import React from 'react'
import Page from "../components/Page";
import SellCarDetails from '../sections/@SellCar';
import NavBarOther from "../components/Layout/NarBarOther";

const SellCar = () => {
    React.useEffect(() => {
        console.log("contact us us call");
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }, []);
      return (
      
        <Page title="Progressive auto sports" description="here is an description">
          <NavBarOther/>
          <SellCarDetails/>
        </Page>
      );
}

export default SellCar