import React, { useEffect } from "react";
import { Box, Button, Card, Divider, Grid, Typography } from "@mui/material";
import Img from "../core/Img";
import Center from "../core/Center";
import Title from "../core/Title";
import CartTitle from "../core/CardTitle";
import ClipButton from "../core/ClipButton";
import Between from "../core/Between";
import productList from "../assets/carDetails/productList.json";
import { Link } from "react-router-dom";
import NavBarOther from "../components/Layout/NarBarOther";
type buycarProps = {
  title?: string;
};
function BuyCar(props: buycarProps) {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  var nf = new Intl.NumberFormat();

  return (
    <React.Fragment>
      <NavBarOther />
      <Box
        sx={{
          position: "relative",
          height: "100%",
        }}
      >
        <Grid
          container
          spacing={3}
          sx={{
            justifyContent: "center",
            display: "flex",
          }}
          mt={{md:3,sm:0.5,xs:0.5}}
        >
          <Grid item md={9}  >
            <Box>
              <Typography
                variant="h3"
                px={2}
                py={1}
                sx={{ color: "#000", fontWeight: "bolder" }}
              >
                Buy your dream car
              </Typography>
            </Box>
          </Grid>
          <Grid item md={9}>
            <Grid
              container
              sx={{
                justifyContent: "center",
                display: "flex",
              }}
            >
              {productList.map((data, id) => {
                return (
                  <Grid
                    item
                    md={4}
                    sm={8}
                    xs={12}
                    p={{ sm: 1.5, xs: 1 }}
                    key={id}
                  >
                    <Link key={data.id} to={`/buycar/${data.id}`}>
                      <Card
                        sx={{
                          borderRadius: "20px",
                          boxShadow:
                            "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                        }}
                      >
                        <Center padding={0}>
                          <Box sx={{ position: "relative" }}>
                            {!data.STATUS && (
                              <Button
                                size={"small"}
                                sx={{
                                  position: "absolute",
                                  background: "#CF0A0A",
                                  color: "#fff",
                                  border: "none",
                                  fontWeight: "bolder",
                                  borderBottomRightRadius: "20px",
                                }}
                              >
                                Sold Out
                              </Button>
                            )}
                            <Img
                              imgUrl={`/${data.IMAGE_FOLDER}/2.jpeg`}
                              height={"200px"}
                              width={"100%"}
                              borderRadius={"5px"}
                            />
                          </Box>
                        </Center>
                        <Between px={2}>
                          <Center>
                            <CartTitle
                              title={data["BRAND "]}
                              subtitle={data["VERIENT "]}
                            />
                          </Center>
                          <Center>
                            <ClipButton title={` ${data.YEAR}`} />
                          </Center>
                        </Between>

                        <Typography
                          sx={{
                            color: "#06",
                            px: 1,
                            py: 2,
                            fontWeight: "bold",
                            fontSize: 12,
                          }}
                        >
                          Feature
                        </Typography>
                        <Between px={2}>
                          <Typography px={0}>
                            Price : ₹ {nf.format(data["PRICE "])}
                          </Typography>
                          <Center>
                            <Box display={"flex"}>
                              <Typography
                                sx={{
                                  color: "#000",
                                  px: 0,
                                  fontWeight: "bold",
                                  fontSize: 14,
                                }}
                              >
                                KM/Miles : {data["KM / MLS"]}
                              </Typography>
                            </Box>
                          </Center>
                        </Between>
                        <Between px={2}>
                          <Center>
                            <Box display={"flex"}>
                              <Typography
                                sx={{
                                  color: "#000",

                                  fontWeight: "bold",
                                  fontSize: 15,
                                }}
                              >
                                Owner : {data.OWNER}
                              </Typography>
                            </Box>
                          </Center>
                          <Center>
                            <Box display={"flex"}>
                              <Typography
                                sx={{
                                  color: "#000",
                                  fontWeight: "bold",
                                  fontSize: 15,
                                }}
                              >
                                Body Type :{data.TYPE}
                              </Typography>
                            </Box>
                          </Center>
                        </Between>
                      </Card>
                    </Link>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}

export default BuyCar;
