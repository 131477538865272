import { Box, Button, Grid, Paper } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import PhoneIcon from "@mui/icons-material/Phone";
import SocialMedai from "../contactUsComponents/socialMedai";
import Center from "../../core/Center";

const Buttons = () => {
  return (
    <Box>
      <Paper
        sx={{
          display: "flex",
          width: { md: "380px", sm: "100%", xs: "100%" },
          height: "100%",
          background: "none",
          boxShadow: "none",
          borderRadius: "0",
        }}
      >
        <Grid
          container
          sx={{
            width: { md: "400px", sm: "100%", xs: "100%" },
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          spacing={{ md: 2, sm: 2, xs: 2 }}
        >
          <Grid item md={6} sm={12} xs={12}>
            <Link to={"/buycar"}>
              <Button
                variant="contained"
                size={"large"}
                fullWidth
                sx={{
                  color: "#121212",
                  background: "#FFD656",
                  height: "60px",
                  fontWeight: "bolder",
                  textTransform: "uppercase",
                  textAlign: "center",
                  borderRadius: "10px",
                  fontSize: "19px",
                }}
              >
                Our collection
              </Button>
            </Link>
          </Grid>

          <Grid item md={6} sm={12} xs={12}>
            <Button
              endIcon={<PhoneIcon sx={{ height: "30px" }} />}
              variant="contained"
              fullWidth
              size={"large"}
              sx={{
                height: "60px",
                color: "#121212",
                background: "#FFD656",
                fontSize: "19px",
                fontWeight: "bolder",
                textTransform: "uppercase",
                justifyContent: "space-between",
                borderRadius: "10px",
              }}
              onClick={(e) => {
                window.location.href = "tel:+919871617270";
                e.preventDefault();
              }}
            >
              Contact Us
            </Button>
          </Grid>

          <Grid item md={12} sm={12} my={{ md: 3, sm: 2, xs: 2 }}>
            <Center>
              <SocialMedai />
            </Center>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default Buttons;
