import { Grid, Box, Typography } from "@mui/material";
import React from "react";
import MessageIcon from "@mui/icons-material/Message";
import CallIcon from "@mui/icons-material/Call";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
const Details = () => {
  return (
    <Box>
      <Grid container px={2} py={2}>
        {/* block-1 */}
        <Grid
          item
          xs={2}
          sx={{
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
          }}
        >
          <MailOutlineOutlinedIcon sx={{ fontSize: "40px", color: "#B2B2B2" }} />
        </Grid>
        
        <Grid item xs={10}>
          <Box>
            <Typography
              variant="h4"
              sx={{ color: "#000", textTransform: "lowercase",fontSize:{xs:"20px"} }}
            >
              Progressiveautosportsofficial
            </Typography>
            <Typography> The best way to get answer faster. </Typography>
          </Box>
        </Grid>
        {/* block-2 */}
       

        {/* block-3  */}

        <Grid
          item
          xs={2}
          sx={{
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
          }}
        >
          <CallOutlinedIcon sx={{ fontSize: "40px", color: "#B2B2B2" }} />
        </Grid>
        <Grid item xs={10} sx={{ padding: "0.5rem" }}>
          <Box>
            <Typography variant="h5" sx={{ color: "#000" }}>
              For Vintage & Classic Cars
            </Typography>
            <Typography variant="h4" sx={{ color: "#000" }}>
              (+91) 7000 076 607
            </Typography>
          </Box>
        </Grid>

        <Grid
          item
          xs={2}
          p={1}
          sx={{
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
          }}
        >
          <CallOutlinedIcon sx={{ fontSize: "40px", color: "#B2B2B2" }} />
        </Grid>
        <Grid item xs={10} sx={{ padding: "0.5rem" }}>
          <Box>
            <Typography variant="h5" sx={{ color: "#000" }}>
              For Preowned Cars
            </Typography>
            <Typography variant="h4" sx={{ color: "#000" }}>
              (+91) 7000 076 608
            </Typography>
          </Box>
        </Grid>

        <Grid
          item
          xs={2}
          sx={{
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
          }}
        >
          <CallOutlinedIcon sx={{ fontSize: "40px", color: "#B2B2B2" }} />
        </Grid>
        <Grid item xs={10} sx={{ padding: "0.5rem" }}>
          <Box>
            <Typography variant="h5" sx={{ color: "#000" }}>
              For Vintage & Classic Cars
            </Typography>
            <Typography variant="h4" sx={{ color: "#000" }}>
              (+91) 7000 076 609
            </Typography>
          </Box>
        </Grid>

      </Grid>
    </Box>
  );
};

export default Details;
