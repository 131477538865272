import { Grid, Box } from "@mui/material";
import React from "react";
import Card from "../../core/Card";
import SubTitle from "../../core/SubTitle";
import Title from "../../core/Title";
import useStyles from "./planForSellStyle";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Person3Icon from "@mui/icons-material/Person3";
import CarRentalIcon from "@mui/icons-material/CarRental";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import OutLineButton from "../../core/OutLineButton";
import Center from "../../core/Center";
import { Link } from "react-router-dom";
import carSel from "../../assets/images/carSel.png";

const PlanForSell = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Box sx={{ display: { md: "block", sm: "none", xs: "none" } }}>
        <Grid container className={classes.classes.center}>
          <Grid item md={4.5} sm={12} p={{ md: 5 }} sx={{ width: "100%" }}>
            <Title title="Are you planning" />
            <SubTitle title="to sell a car" />

            <Grid container py={{ md: 5 }} sx={{ width: "100%" }}>
              <Grid item sm={6}>
                <Box className={classes.classes.spaceBetween}>
                  <Box className={classes.classes.spaceBetween}>
                    <Card title="Outright" subTitle="Sale">
                      <LoyaltyIcon
                        sx={{
                          fontSize: "30px",
                          color: "#121212",
                          margin: "15px",
                          fontWeight: "500",
                        }}
                      />
                    </Card>
                  </Box>
                </Box>
              </Grid>
              <Grid item sm={6}>
                <Box className={classes.classes.spaceBetween}>
                  <Box className={classes.classes.spaceBetween}>
                    <Card title="Best Offer" subTitle="in 24 Hours">
                      <AccessTimeIcon
                        sx={{
                          fontSize: "30px",
                          color: "#121212",
                          margin: "15px",
                          fontWeight: "500",
                        }}
                      />
                    </Card>
                  </Box>
                </Box>
              </Grid>
              <Grid item sm={6}>
                <Box className={classes.classes.spaceBetween}>
                  <Box className={classes.classes.spaceBetween}>
                    <Card title="1200+ Satisfied" subTitle="Customers">
                      <Person3Icon
                        sx={{
                          fontSize: "30px",
                          color: "#121212",
                          margin: "15px",
                          fontWeight: "500",
                        }}
                      />
                    </Card>
                  </Box>
                </Box>
              </Grid>
              <Grid item sm={6}>
                <Box className={classes.classes.spaceBetween}>
                  <Box className={classes.classes.spaceBetween}>
                    <Card title="Hassle Free" subTitle="Processing">
                      <CarRentalIcon
                        sx={{
                          fontSize: "30px",
                          color: "#121212",
                          margin: "15px",
                          fontWeight: "500",
                        }}
                      />
                    </Card>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Link
              to="#"
              onClick={(e) => {
                window.location.href = "tel:+919871617270";
                e.preventDefault();
              }}
            >
              <OutLineButton text="Get To Sell" />
            </Link>
          </Grid>
          <Grid
            item
            sm={12}
            md={5.5}
            sx={{
              display: { md: "flex", sm: "none" },
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={carSel}
              height={"350px"}
              
            />
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ display: { md: "none", sm: "none", xs: "block" },mb:2 }}>
        <Grid container className={classes.classes.center}>
          <Grid
            item
            sm={10}
            md={5.5}
            sx={{ display: { md: "none", sm: "flex" }, p: 2 }}
          >
            <Center>
              <img
                src={carSel}
                height={"200px"}
                
              />
            </Center>
          </Grid>
          <Grid
            item
            md={4.5}
            sm={11}
            xs={11}
            p={{ md: 5 }}
            sx={{ width: "100%" }}
          >
            <Box p={2}>
              <Title title="Are you planning" />
              <SubTitle title="to sell a car" />
            </Box>

            <Grid container py={{ md: 5 }} spacing={1} sx={{ width: "100%" }}>
              <Grid item sm={6}>
                <Box className={classes.classes.spaceBetween}>
                  <Box className={classes.classes.spaceBetween}>
                    <Card title="Outright" subTitle="Sale">
                      <LoyaltyIcon
                        sx={{
                          fontSize: "30px",
                          color: "#121212",
                          margin: "15px",
                          fontWeight: "500",
                        }}
                      />
                    </Card>
                  </Box>
                </Box>
              </Grid>
              <Grid item sm={6}>
                <Box className={classes.classes.spaceBetween}>
                  <Box className={classes.classes.spaceBetween}>
                    <Card title="Best Offer" subTitle="in 24 Hours">
                      <AccessTimeIcon
                        sx={{
                          fontSize: "30px",
                          color: "#121212",
                          margin: "15px",
                          fontWeight: "500",
                        }}
                      />
                    </Card>
                  </Box>
                </Box>
              </Grid>
              <Grid item sm={6}>
                <Box className={classes.classes.spaceBetween}>
                  <Box className={classes.classes.spaceBetween}>
                    <Card title="1200+ Satisfied" subTitle="Customers">
                      <Person3Icon
                        sx={{
                          fontSize: "30px",
                          color: "#121212",
                          margin: "15px",
                          fontWeight: "500",
                        }}
                      />
                    </Card>
                  </Box>
                </Box>
              </Grid>
              <Grid item sm={6}>
                <Box className={classes.classes.spaceBetween}>
                  <Box className={classes.classes.spaceBetween}>
                    <Card title="Hassle Free" subTitle="Processing">
                      <CarRentalIcon
                        sx={{
                          fontSize: "30px",
                          color: "#121212",
                          margin: "15px",
                          fontWeight: "500",
                        }}
                      />
                    </Card>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Link
              to="#"
              onClick={(e) => {
                window.location.href = "tel:+919871617270";
                e.preventDefault();
              }}
            >
              <OutLineButton text="Get To Sell" />
            </Link>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ display: { md: "none", sm: "block", xs: "none" } }}>
        <Grid container className={classes.classes.center}>
          <Grid
            item
            sm={12}
          >
            <Center>
              <img
                src={carSel}
                height={"300px"}
                
              />
            </Center>
          </Grid>
          <Grid
            item
            sm={10}
            p={{ md: 5 }}
          >
            <Box p={2}>
              <Title title="Are you planning" />
              <SubTitle title="to sell a car" />
            </Box>

            <Grid container py={{ md: 5 }} spacing={1} sx={{ width: "100%" }}>
              <Grid item sm={6}>
                <Box className={classes.classes.spaceBetween}>
                  <Box className={classes.classes.spaceBetween}>
                    <Card title="Outright" subTitle="Sale">
                      <LoyaltyIcon
                        sx={{
                          fontSize: "30px",
                          color: "#121212",
                          margin: "15px",
                          fontWeight: "500",
                        }}
                      />
                    </Card>
                  </Box>
                </Box>
              </Grid>
              <Grid item sm={6}>
                <Box className={classes.classes.spaceBetween}>
                  <Box className={classes.classes.spaceBetween}>
                    <Card title="Best Offer" subTitle="in 24 Hours">
                      <AccessTimeIcon
                        sx={{
                          fontSize: "30px",
                          color: "#121212",
                          margin: "15px",
                          fontWeight: "500",
                        }}
                      />
                    </Card>
                  </Box>
                </Box>
              </Grid>
              <Grid item sm={6}>
                <Box className={classes.classes.spaceBetween}>
                  <Box className={classes.classes.spaceBetween}>
                    <Card title="1200+ Satisfied" subTitle="Customers">
                      <Person3Icon
                        sx={{
                          fontSize: "30px",
                          color: "#121212",
                          margin: "15px",
                          fontWeight: "500",
                        }}
                      />
                    </Card>
                  </Box>
                </Box>
              </Grid>
              <Grid item sm={6}>
                <Box className={classes.classes.spaceBetween}>
                  <Box className={classes.classes.spaceBetween}>
                    <Card title="Hassle Free" subTitle="Processing">
                      <CarRentalIcon
                        sx={{
                          fontSize: "30px",
                          color: "#121212",
                          margin: "15px",
                          fontWeight: "500",
                        }}
                      />
                    </Card>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Link
              to="#"
              onClick={(e) => {
                window.location.href = "tel:+919871617270";
                e.preventDefault();
              }}
            >
              <OutLineButton text="Get To Sell" />
            </Link>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default PlanForSell;
