import React, { Fragment } from "react";
import Title from "../../core/Title";
import Img from "../../core/Img";
import { Grid, Box } from "@mui/material";
import Slider from "react-slick";
import Center from "../../core/Center";
import SubTitle from "../../core/SubTitle";
import { NextBtn, PreviousBtn } from "../Slick";
import useStyles from "./styles";

function Brands() {
  const settings = {
    prevArrow: <PreviousBtn />,
    nextArrow: <NextBtn />,
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "0px",
    slidesToShow: 5,
    speed: 500,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  const classes = useStyles();

  return (
    <Box
      sx={{
        height: { md: "100vh", sm: "100%", xs: "100%" },
        background: "#000",
      }}
      className={classes.classes.center}
    >
      <Grid container className={classes.classes.center}>
        <Grid item md={10} sm={12} sx={{ width: "100%", p: 2 }}>
          <Box py={{ md: 10 }}>
            <Title title="BRANDS" color="#fff"></Title>
            <SubTitle title="WE WORK IN" />
          </Box>
        </Grid>

        <Grid
          item
          md={10}
          sm={10}
          xs={10}
          sx={{ height: "100%", padding: { xs: "0px", md: 3, sm: 2 } }}
        >
          <Grid container sx={{ width: "100%", py: 7 }}>
            <Slider {...settings}>
              <Center>
                <Img imgUrl="https://thecarmall.co.in/wp-content/uploads/2022/05/Land-Rover.png"></Img>
              </Center>

              <Center>
                <Img imgUrl="https://thecarmall.co.in/wp-content/uploads/2022/05/Ford-1.png"></Img>
              </Center>

              <Center>
                <Img imgUrl="https://thecarmall.co.in/wp-content/uploads/2022/05/Jaguar.png"></Img>
              </Center>

              <Center>
                <Img imgUrl="https://www.autobest.co.in/uploads/brand/882456411757.png"></Img>
              </Center>

              <Center>
                <Img imgUrl="https://www.autobest.co.in/uploads/brand/825851611685.png"></Img>
              </Center>
              <Center>
                <Img imgUrl="https://www.autobest.co.in/uploads/brand/485685214215.png"></Img>
              </Center>
              <Center>
                <Img imgUrl="https://www.autobest.co.in/uploads/brand/886569112599.png"></Img>
              </Center>
              <Center>
                <Img imgUrl="https://www.autobest.co.in/uploads/brand/115682135814.png"></Img>
              </Center>
              {/* <Center>
                <Img imgUrl="https://www.autobest.co.in/uploads/brand/415544129752.png" height="130px"></Img>
              </Center> */}

              {/* <Center>
                <Img imgUrl="https://www.autobest.co.in/uploads/brand/241074153861.jpg" height="130px"></Img>
              </Center> */}
              <Center>
                <Img imgUrl="https://www.autobest.co.in/uploads/brand/616315365227.png"></Img>
              </Center>
            </Slider>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Brands;
