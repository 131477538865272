import React from "react";
import { Box } from "@mui/material";
interface BetweenInterface {
  children?: React.ReactNode;
  m?: number;
  p?: number;
  px?: number;
  py?:number;
}
const Between = (props: BetweenInterface) => {
  return (
    <Box
      sx={{
        justifyContent: "space-between",
        display: "flex",

      }}
      p={props.p}
      m={props.m}
      px={props.px}
      py={props.py}
    >
      {props.children}
    </Box>
  );
};
export default Between;
