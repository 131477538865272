import React from "react";
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";
import Img from "../core/Img";
import Center from "../core/Center";
import CartTitle from "../core/CardTitle";
import ClipButton from "../core/ClipButton";
import Between from "../core/Between";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";
import Groups2Icon from "@mui/icons-material/Groups2";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import DoorFrontIcon from "@mui/icons-material/DoorFront";
import DirectionsCarFilledIcon from "@mui/icons-material/DirectionsCarFilled";
import RouteIcon from "@mui/icons-material/Route";
import { Theme, useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import productList from "../assets/carDetails/productList.json";
import brands from "../assets/carDetails/totalBrand.json";
import { Link, useLocation } from "react-router-dom";
import TuneTwoToneIcon from "@mui/icons-material/TuneTwoTone";
import NavBarOther from "../components/Layout/NarBarOther";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const carType = ["suv", "sedan", "convertible", "coupe", "cuv", "hatchback"];

const sortOption = [
  "Sort by Default",
  "Sort By Name",
  "Sort By Price",
  "LowToHigh",
  "HighToLow",
];

function getStyles(name: string, personName: readonly string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const Inventory = () => {
  type stateInterFace = {
    bodyType?: string;
  };

  const { state } = useLocation();

  const theme = useTheme();
  const [brand, setBrand] = React.useState<string[]>([]);
  const [bodyType, setBodyType] = React.useState<string[]>([]);
  const [sortFilter, setSortfilter] = React.useState<number>(0);

  const [carList, setCarList] = React.useState(productList);
  const [open, setOpen] = React.useState(false);

  var nf = new Intl.NumberFormat();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (event: SelectChangeEvent<typeof brand>) => {
    const {
      target: { value },
    } = event;
    setBrand(typeof value === "string" ? value.split(",") : value);
  };

  const priceChangeHandler = (event: SelectChangeEvent<Number>) => {
    setSortfilter(+event.target.value);
    if (sortFilter === 1) {
      const sortingData = carList.sort((first, second) => {
        if (first["PRICE "] > second["PRICE "]) {
          return 1;
        } else {
          return -1;
        }
      });

      setCarList(sortingData);
    } else if (sortFilter === 0) {
      const sortingData = carList.sort((first, second) => {
        if (first["PRICE "] < second["PRICE "]) {
          return 1;
        } else {
          return -1;
        }
      });

      setCarList(sortingData);
    }
  };

  const handleChangeBodyType = (event: SelectChangeEvent<typeof bodyType>) => {
    const {
      target: { value },
    } = event;
    setBodyType(typeof value === "string" ? value.split(",") : value);
  };

  const filterCarHandler = () => {
    if (brand.length > 0) {
      const FilterData = productList.filter((List) => {
        for (let i = 0; i < brand.length; i++) {
          if (brand[i] === List["BRAND "]) {
            if (bodyType.length > 0) {
              for (let j = 0; j < bodyType.length; j++) {
                if (bodyType[j] === List.TYPE) {
                  return true;
                }
              }
            } else {
              return true;
            }
          }
        }
      });
      setCarList(FilterData);
    } else if (brand.length === 0 && bodyType.length > 0) {
      const FilterData = productList.filter((List) => {
        for (let i = 0; i < bodyType.length; i++) {
          if (bodyType[i] === List.TYPE) {
            return true;
          }
        }
      });
      setCarList(FilterData);
    } else {
      setCarList(productList);
    }
    handleClose();
  };

  React.useEffect(() => {
    if (state?.bodyType) {
      const FilterData = productList.filter((List) => {
        if (state.bodyType === List.TYPE) {
          return true;
        }
      });
      setCarList(FilterData);
    } else {
      setCarList(productList);
    }
  }, []);

  React.useEffect(() => {

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { md: 500, sm: 400, xs: "90%" },
    height: 350,
    bgcolor: "#f1f1f1",
    borderRadius: "20px",
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset",
    p: { md: 4, sm: 3, xs: 1 },
  };

  return (
    <React.Fragment>
      <NavBarOther />
      <Box
        sx={{
          position: "relative",
          height: "100%",
        }}
      >
        <Grid
          container
          spacing={3}
          sx={{
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Grid item md={9} sm={12} mt={3}>
            <Grid container>
              <Grid item md={9} sm={12} xs={12}>
                <Box>
                  <Typography
                    variant="h3"
                    px={2}
                    py={1}
                    sx={{ color: "#000", fontWeight: "bolder" }}
                  >
                    Search Your Dream Car
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={3} sm={12} xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    px: { xs: 2 },
                  }}
                >
                  <Tooltip title={"Filter"}>
                    <Button
                      size="large"
                      variant="outlined"
                      sx={{ p: 2, borderRadius: 2 }}
                      onClick={handleOpen}
                    >
                      <TuneTwoToneIcon />
                    </Button>
                  </Tooltip>
                  <FormControl sx={{ width: "100%", mx: 2, borderRadius: 2 }}>
                    <InputLabel>Sort By</InputLabel>
                    <Select label="Sort By" onChange={priceChangeHandler}>
                      <MenuItem value={1}>Price-High To Low</MenuItem>
                      <MenuItem value={0}>Price-Low To High</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid item md={9} sm={12} xs={12}>
            <Grid
              container
              sx={{
                justifyContent: "center",
                display: "flex",
              }}
            >
              {carList.map((data, id) => {
                return (
                  <Grid item md={4} sm={8} xs={12} p={{sm:1.5,xs:1}} key={id}>
                    <Link  to={`/buycar/${data.id}`}>
                      <Card
                        sx={{
                          borderRadius: "20px",
                          boxShadow:
                            "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                        }}
                      >
                        <Center padding={0}>
                          <Box sx={{ position: "relative" }}>
                            {!data.STATUS && (
                              <Button
                                size={"small"}
                                sx={{
                                  position: "absolute",
                                  background: "#CF0A0A",
                                  color: "#fff",
                                  border: "none",
                                  fontWeight: "bolder",
                                  borderBottomRightRadius: "20px",
                                }}
                              >
                                Sold Out
                              </Button>
                            )}
                            <Img
                              imgUrl={`/${data.IMAGE_FOLDER}/2.jpeg`}
                              width={"100%"}
                              height={"200px"}
                              borderRadius={"5px"}
                            />
                          </Box>
                        </Center>
                        <Between px={2}>
                          <Center>
                            <CartTitle
                              title={data["BRAND "]}
                              subtitle={data["VERIENT "]}
                            />
                          </Center>
                          <Center>
                            <ClipButton title={` ${data.YEAR}`} />
                          </Center>
                        </Between>

                        <Typography
                          sx={{
                            color: "#06",
                            px: 1,
                            py: 2,
                            fontWeight: "bold",
                            fontSize: 12,
                          }}
                        >
                          Feature
                        </Typography>
                        <Between px={2}>
                          <Typography px={0}>
                            Price : ₹ {nf.format(data["PRICE "])}
                          </Typography>
                          <Center>
                            <Box display={"flex"}>
                              <Typography
                                sx={{
                                  color: "#000",
                                  px: 0,
                                  fontWeight: "bold",
                                  fontSize: 14,
                                }}
                              >
                                KM/Miles : {data["KM / MLS"]}
                              </Typography>
                            </Box>
                          </Center>
                        </Between>
                        <Between px={2}>
                          <Center>
                            <Box display={"flex"}>
                              <Typography
                                sx={{
                                  color: "#000",

                                  fontWeight: "bold",
                                  fontSize: 15,
                                }}
                              >
                                Owner : {data.OWNER}
                              </Typography>
                            </Box>
                          </Center>
                          <Center>
                            <Box display={"flex"}>
                              <Typography
                                sx={{
                                  color: "#000",
                                  fontWeight: "bold",
                                  fontSize: 15,
                                }}
                              >
                                Body Type : {data.TYPE}
                              </Typography>
                            </Box>
                          </Center>
                        </Between>
                      </Card>
                    </Link>
                  </Grid>
                );
              })}
              {carList.length === 0 && (
                <Typography p={2} sx={{ textAlign: "center" }}>
                  No Data Found
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {/* //model */}
      <Modal
        open={open}
        onClose={handleClose}
        disableEnforceFocus
        disablePortal={true}
        onBackdropClick={handleOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            sx={{ fontSize: "20px" }}
            component="h2"
            paddingBottom={2}
          >
            Get Your Dream Car
          </Typography>
          <FormControl sx={{ width: "100%", my: 2 }}>
            <InputLabel id="demo-multiple-chip-label">Choose Brands</InputLabel>
            <Select
              multiple
              value={brand}
              onChange={handleChange}
              input={
                <OutlinedInput
                  id="select-multiple-chip"
                  label="Choose Brands"
                />
              }
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {brands.map((name) => (
                <MenuItem
                  divider={false}
                  key={name}
                  value={name}
                  style={getStyles(name, brands, theme)}
                >
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ width: "100%", my: 2 }}>
            <InputLabel id="demo-multiple-chip-label">Body Style</InputLabel>
            <Select
              multiple
              value={bodyType}
              onChange={handleChangeBodyType}
              input={
                <OutlinedInput
                  id="select-multiple-chip"
                  label="Choose Brands"
                />
              }
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {carType.map((name) => (
                <MenuItem
                  divider={false}
                  key={name}
                  value={name}
                  style={getStyles(name, brands, theme)}
                >
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box sx={{ textAlign: "end" }} py={1}>
            <Button variant="outlined" onClick={filterCarHandler}>
              Seach Now
            </Button>
          </Box>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default Inventory;
