import { makeStyles } from "tss-react/mui";
const useStyles = makeStyles({})((theme) => ({
  center: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    height: "100vh",
    [theme.breakpoints.down('md')]:{
      height:"100%",
      position:'relative'
    },
    [theme.breakpoints.down('xs')]:{
      height:"100%",
      position:'relative'
    },
  },
  spaceBetween: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));
export default useStyles;
