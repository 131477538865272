import React from "react";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import {
  Address,
  Details,
  Title,
  Form,
} from "../../components/contactUsComponents";

const SellCarDetails = () => {
  return (
    <Box
      sx={{
        position: "relative",
        height: { md: "100%", sm: "100%", xs: "100%" },
        justifyContent: "center",
        backgroundColor: "#fff",
      }}
    >
      <Grid
        container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: "#fff",
        }}
      >
        <Grid item md={10} sm={12} xs={12} py={5} px={1}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Title title="Want to Sell Car?" subTitle="Progressive auto sports" />
              <Details />
              <Address />
            </Grid>
            <Grid item xs={12} md={6}>
              <Box p={2}>
                <Form />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SellCarDetails;
