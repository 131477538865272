import { Box,  Grid, Typography } from "@mui/material";
import React from "react";
import Center from "../../core/Center";
import Img from "../../core/Img";
import SubTitle from "../../core/SubTitle";
import Title from "../../core/Title";
import { useNavigate } from "react-router";

const CarType = () => {
  const navigate = useNavigate();

  const callInventoryHandler = (bodyType: string) => {
    navigate("/inventory", { state: { bodyType: bodyType } });
  };
  return (
    <Box
      sx={{
        height: { md: "100vh", sm: "100%" },
        justifyContent: "center",
        background: "#fff",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Grid
        container
        sx={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <Grid
          item
          md={9}
          xs={12}
          marginBottom={{ md: 3, sm: 2, xs: 2 }}
          padding={{ xs: 2, md: 0 }}
          sx={{ display: { md: "block", sm: "block", xs: "block" } }}
        >
          <Box paddingBottom={{md:10}}>
            <Title title="Go with" />
            <SubTitle title="your style" />
          </Box>
        </Grid>

        <Grid
          item
          md={10}
          sm={10}
          xs={12}
          sx={{ justifyContent: "center", alignItems: "center" }}
        >
          <Grid container spacing={5}>
            <Grid
              item
              md={4}
              sm={4}
              xs={12}
              onClick={() => callInventoryHandler("coupe")}
            >
              <Center>
                <Box>
                  <Img imgUrl="./carstyle/body1.png" height="50px"></Img>
                  <Typography variant="h6">Coupe</Typography>
                </Box>
              </Center>
            </Grid>
            <Grid
              item
              md={4}
              sm={4}
              xs={12}
              onClick={() => callInventoryHandler("convertible")}
            >
              <Center>
                <Box>
                  <Img imgUrl="./carstyle/body2.png" height="50px"></Img>
                  <Typography variant="h6">Convertible</Typography>
                </Box>
              </Center>
            </Grid>
            <Grid
              item
              md={4}
              sm={4}
              xs={12}
              onClick={() => callInventoryHandler("cuv")}
            >
              <Center>
                <Box>
                  <Img imgUrl="./carstyle/body3.png" height="60px"></Img>
                  <Typography variant="h6">CUV</Typography>
                </Box>
              </Center>
            </Grid>
            <Grid
              item
              md={4}
              sm={4}
              xs={12}
              onClick={() => callInventoryHandler("sedan")}
            >
              <Center>
                <Box>
                  <Img imgUrl="./carstyle/body4.png" height="50px"></Img>
                  <Typography variant="h6">Sedan</Typography>
                </Box>
              </Center>
            </Grid>
            <Grid
              item
              md={4}
              sm={4}
              xs={12}
              onClick={() => callInventoryHandler("hatchback")}
            >
              <Center>
                <Box>
                  <Img imgUrl="./carstyle/body5.png" height="50px"></Img>
                  <Typography variant="h6">Hatchback</Typography>
                </Box>
              </Center>
            </Grid>
            <Grid
              item
              md={4}
              sm={4}
              xs={12}
              onClick={() => callInventoryHandler("suv")}
            >
              <Center>
                <Box>
                  <Img imgUrl="./carstyle/body7.png" height="50px"></Img>
                  <Typography variant="h6">SUV</Typography>
                </Box>
              </Center>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CarType;
